import { inject } from "mobx-react";
import { Component } from "react";
import type { Store } from "@inferno/renderer-shared-core";
import { ResponsiveImage } from "../../components/ResponsiveImage.component";
import type { SrcSet } from "@inferno/renderer-shared-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { MagicLink } from "../../ui";
import "./Show.style.scss";
import { RESPONSIVE_IMAGE } from "./../../ui/constants";

interface ShowProps {
  thumb?: string;
  title: string;
  url: string;
  store?: Store;
}

@inject("store")
export class Show extends Component<ShowProps, {}> {
  render() {
    const { store } = this.props;
    if (!store) {
      return null;
    }
    const { site, env } = store;
    const thumbWidth = RESPONSIVE_IMAGE.showThumbWidth;
    let showImgUrl = formatImage(site.sections.design?.station_logo?.id || env.DEFAULT_IMAGE, env.IMAGE_HOST);
    let srcset: SrcSet[] = [];
    if (this.props.thumb) {
      showImgUrl = `${this.props.thumb}?ops=gravity(%22center%22),fit(${thumbWidth},${thumbWidth})&quality=80`;
      srcset = [
        {
          url: `${this.props.thumb}?ops=gravity(%22center%22),fit(${thumbWidth},${thumbWidth})&quality=80`,
          descriptor: "1x",
        },
        {
          url: `${this.props.thumb}?ops=gravity(%22center%22),fit(${thumbWidth * 2},${thumbWidth * 2})&quality=80`,
          descriptor: "2x",
        },
      ];
    }

    return (
      <figure className="component-show">
        <div className="thumb-container">
          <MagicLink to={this.props.url}>
            <ResponsiveImage
              src={showImgUrl}
              srcset={srcset}
              initialWidth={RESPONSIVE_IMAGE.showThumbWidth}
              initialHeight={RESPONSIVE_IMAGE.showThumbHeight}
              alt={`Photo of ${this.props.title}`}
            />
          </MagicLink>
        </div>
        <figcaption>
          <MagicLink className="card-title" to={this.props.url}>
            {this.props.title}
          </MagicLink>
        </figcaption>
      </figure>
    );
  }
}

export default Show;
